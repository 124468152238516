
import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';


const initialState = {
   WebsiteData: {}
};


export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
   const [state, dispatch] = useReducer(AppReducer, initialState);
   // Actions for changing state

   function addWebData(item) {
      dispatch({
         type: 'ADD_ITEM',
         payload: item
      });
    
   }
   function removeWebData(item) {
      dispatch({
         type: 'REMOVE_ITEM',
         payload: item
      });
   }
   return (
      <GlobalContext.Provider value={{ WebsiteData: state.WebsiteData, addWebData, removeWebData }}>
         {children}
      </GlobalContext.Provider>
   )
}
import React, { useEffect, useContext, useState, useRef } from 'react';
import Clients from '../../Components/_shared/clients';
import PageBanner from '../../Components/_shared/pagebanner';
import { seo } from '../../_helpers/seo';
import "./career.css"
import { GlobalContext } from '../../_helpers/GlobalState';
import Loader from '../../Components/_shared/loader';
import axios from "axios";
import { ApiUrl } from "../../../const";
import jobs from "./careerList.json";


function Career() {
    // state variables for the file and the body properties
    const [showElement, setShowElement] = React.useState(false);
    const inputRef = useRef(null);
    const [File, setFile] = useState(null);
    const [Position, setPosition] = useState("Other");
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Phone, setPhone] = useState("");

    const resetForm = () => {
        inputRef.current.value = null;
        setFile(null);
        setPosition("Other");
        setName("");
        setEmail("");
        setPhone("");
    }

    // function to handle file input change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // function to handle position input change
    const handlePositionChange = (e) => {
        setPosition(e.target.value);
    };

    // function to handle name input change
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    // function to handle email input change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // function to handle phone input change
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    // function to submit the form data
    const handleSubmitAsync = async (e) => {
        e.preventDefault();


        await HandleCareerFormFilePreUplaodAsync();





    };


    const { WebsiteData } = useContext(GlobalContext);
    let Career = WebsiteData?.Website?.CareerPage;

    useEffect(() => {
        seo({
            title: 'RKG | Career',
            metaDescription: ''
        });

    }, [])



    return (
        <>
            {
                Career ?
                    <>
                        <PageBanner {...Career.Banner} />
                        {Career.BenefitsSection.IsVisible &&
                            <section className="what-they-say">


                                <div className="container">
                                    <div className="row m-0 ">

                                        <div className="col-lg-5">
                                            <div className="car-left">
                                                <img src={Career.LifeSection.ImageUrl} alt="" className="img-fluid" />
                                            </div>
                                        </div>

                                        <div className="col-lg-7">
                                            <div className="car-right">
                                                <h3>{Career.LifeSection.HeaderOne} <span>{Career.LifeSection.HeaderTwo}</span></h3>
                                                {Career.LifeSection.Paragraph}
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </section>
                        }

                        {Career.ApplyForPositionForm.IsVisible &&
                            <section className=" career-ser-2 car-off ">

                                <div className="container">
                                    <div className="row  ">
                                        <div className="col-lg-12">
                                            <div className="section-heading" >
                                                <h2>Apply for<br /><em>Position</em></h2>
                                            </div>


                                            <div className="appl-form">
                                                <form className="row" id="jobFrm" onSubmit={async (e) => await handleSubmitAsync(e)}>

                                                    <div className="col-md-6">
                                                        <div className="pos-rel">
                                                            <select id="position" value={Position} onChange={handlePositionChange} required className="form-select">
                                                                {
                                                                    jobs.map((categ) => {
                                                                        let KeyName = Object.keys(categ)[0]
                                                                        return (

                                                                            < optgroup key={KeyName} label={KeyName}>
                                                                                {
                                                                                    categ[KeyName].map((subCat, index) => {
                                                                                        return (
                                                                                            < option key={index} value={`${KeyName} - ${subCat}`} > {subCat}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="pos-rel">
                                                            <input type="text" className="form-control" placeholder="Your Name" id="name" value={Name} onChange={handleNameChange} required />
                                                            <span id="name_job_error" className="error"></span>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="pos-rel">
                                                            <input className="form-control" type="email" name="email" placeholder="Your Email" value={Email} onChange={handleEmailChange} required />
                                                            <span id="email_job_error" className="error"></span>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6">
                                                        <div className="pos-rel">
                                                            <input className="form-control" type="tel" name="phone_number" maxLength="13" placeholder="Your Phone" value={Phone} onChange={handlePhoneChange} required />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 col-lg-6">

                                                        <div>
                                                            <div className="pos-rel">
                                                                <div className="custom-file form__field p-2 mb-0">
                                                                    <input type="file" accept="application/pdf" className="form-control m-0" id="file" onChange={handleFileChange} required name="file" placeholder="Your Resume" ref={inputRef} />
                                                                </div>
                                                            </div>
                                                            <span id="resume_job_error" className="error"></span>
                                                            <p><small>Allowed file extensions: pdf. Max. file size: 2MB</small></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 col-lg-6 text-right">
                                                        <button type="submit" className="btn btn-primary" id="job-btn">Apply Job</button>
                                                    </div>
                                                    <div>
                                                    </div>
                                                </form>
                                                <div className="col-lg-12">
                                                    {showElement ?
                                                        <div className="alert alert-primary" role="alert">
                                                            We will contact you soon. Thank You.
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </section >
                        }

                        {
                            Career.BenefitsSection.IsVisible &&
                            <section className=" career-ser-2 car-bene ">
                                <div className="container">
                                    <div className="row mb-3 ">
                                        <div className="col-lg-12">
                                            <h4>{Career.BenefitsSection.Header}</h4>
                                            <p>{Career.BenefitsSection.Paragraph}</p>
                                        </div>
                                    </div>
                                    <div className="row  ">
                                        {Career.BenefitsSection.Benefits.map((item, index) => {
                                            return (
                                                <div className="col-lg-4" key={index}>
                                                    <div className="bene-list">
                                                        <i className={item.IconClass}></i>
                                                        <h5>{item.Header}</h5>
                                                        <p>{item.Paragraph}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                        }

                        {
                            Career.ClienTeleSection.IsVisible &&
                            <Clients />

                        }

                    </>
                    :
                    <>
                        <Loader />
                    </>}
        </>

    );

    function HandleCareerFormAsync(response) {
        var formData = {}
        formData.Position = Position;
        formData.Name = Name;
        formData.Email = Email;
        formData.Phone = Phone;
        formData.DocumentId = response.data.payload.Id;

        // make a post request to the API endpoint with the FormData object as the data
        axios.post(`${process.env.REACT_APP_API_URL}${ApiUrl.CareerPostUrl}`, formData)
            .then((response) => {
                resetForm();
                setShowElement(true);

                // handle success response
                setTimeout(function () {
                    setShowElement(false);

                }, 6000);
            })
            .catch((error) => {
                // handle error response
                console.error(error);
            });
    }


    function HandleCareerFormFilePreUplaodAsync() {

        const formData = new FormData();
        // append the file and the body properties to the FormData object
        let nameappend = Math.floor((Math.random()) * 0x10000).toString(16);

        formData.append("file", File, `${nameappend}_${File.name}`);

        axios.post(`${process.env.REACT_APP_API_URL}${ApiUrl.DocumentPostUrl}`, formData)
            .then((response) => {
                //Insert In career table
                HandleCareerFormAsync(response);
            })
            .catch((error) => {
                // handle error response
                console.error(error);
            });
    }
}
export default Career;



import Slider from "react-slick";
import { useContext } from 'react';
import { GlobalContext } from '../../_helpers/GlobalState';

function Clients() {

    const { WebsiteData } = useContext(GlobalContext);

    var settings = {
        dots: true,
        infinite: true,
        speed: 700,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 1,
        pauseOnHover: true,
        centerMode: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (

        <>
            <section className="container expanded">

                <div className="col-md-10 col-lg-8">
                    <div className="section-heading">
                        <h2>Our<br /><em>Clientele</em>.</h2>
                    </div>
                    <div className="header-section">

                    </div>
                </div>

            </section>

            <section className="container clientslider">
                <Slider {...settings}>

                    {
                        WebsiteData.Website.Clientele.ClientImages.map(clients => {
                            return (
                                <div className="single-logo" key={clients}>
                                    <img src={clients} alt="" />
                                </div>)
                        })

                    }
                </Slider>
            </section>


        </>
    )
}

export default Clients;
export default function PageBanner(props) {
    let headerwem = props.Header.split("|");
    let backgroundimage = {
        background: `linear-gradient(to right, rgb(205 192 192 / 50%), rgb(0 0 0)), url(${props.ImageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
    }

    return (
        <>
            <div className="page-banner change-name" style={backgroundimage}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="header-text">
                                <h2>{headerwem[0]}<em>{headerwem[1]} </em></h2>
                                <p>{props.Paragraph}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

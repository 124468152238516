import React, { useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import useHasScrolledDown from '../../_helpers/useHasScrolledDown';
import { GlobalContext } from '../../_helpers/GlobalState';
import axios from "axios";
import { ApiUrl } from '../../../const'

export function Header() {
    const { WebsiteData } = useContext(GlobalContext);
    const { addWebData } = useContext(GlobalContext);

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}${ApiUrl.HomePageUrl}`).then((response) => {
            addWebData(response.data);

        });


    }, []);

    const hasScrolledDown = useHasScrolledDown();

    function toggleNav() {
        var nav = document.getElementById('navbarNav');
        nav.classList.toggle('show');
    }

    return (<>
        <header id="#top" className={hasScrolledDown ? "nav-scrolled" : ""}>

            <nav className="main-navigation navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <Link className='navbar-brand' to="/" ><img className='brand-logo' src={WebsiteData?.Website?.HomePage?.LogoUrl} alt="Logo" /></Link>

                    <button onClick={toggleNav} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/" > Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/about-us" > About Us</NavLink>

                            </li>
                            <li className="nav-item">
                                <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/our-services" >Services</NavLink>

                            </li>
                            <li className="nav-item">
                                <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/career" > Career</NavLink>

                            </li>
                            <li className="nav-item">
                                <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/our-works" >Gallery</NavLink>

                            </li>
                            <li className="nav-item">
                                <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/contact-us" >Contact Us</NavLink>

                            </li>
                        </ul>

                    </div>
                </div>
            </nav>

        </header>

    </>

    );
}

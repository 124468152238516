import './App.css';
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/templatemo-572-designer.css";
import { Header } from "./Web/Components/_shared/header";
import { Footer } from "./Web/Components/_shared/footer";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import React, { useContext } from 'react';
import axios from "axios";
import Loader from './Web/Components/_shared/loader';
import Career from './Web/Pages/Career/Career';

import { GlobalProvider } from './Web/_helpers/GlobalState';

const closenav = () => {
  var nav = document.getElementById('navbarNav');
  if (nav != null) {

    nav.classList.remove('show');
  }
}
const routerLatency = 200;

const Home = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Web/Pages/Homepage/home")), routerLatency);
  });
});

const Services = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Web/Pages/Services/services")), routerLatency);
  });
});
const Contact = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Web/Pages/Contact/Contact")), routerLatency);

  });
});

const Gallery = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Web/Pages/Gallery/Gallery")), routerLatency);
  });
});

const About = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Web/Pages/About/About")), routerLatency);
  });
});



function App() {
  const location = useLocation()
  React.useEffect(() => {
    // runs on location, i.e. route, change
    closenav();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [location])


  return (
    <>
      <GlobalProvider>
        <Routes>
          <Route exact path="/" element={
            <React.Suspense fallback={<Loader />}>
              <Layout />
            </React.Suspense>} >

            <Route index element={
              <React.Suspense fallback={<Loader />}>
                <Home />
              </React.Suspense>} />

            <Route path="/about-us" element={
              <React.Suspense fallback={<Loader />}>
                <About />
              </React.Suspense>} />
            <Route path="/our-services" element={
              <React.Suspense fallback={<Loader />}>
                <Services />
              </React.Suspense>} />
            <Route path="/career" element={
              <React.Suspense fallback={<Loader />}>
                <Career />

              </React.Suspense>} />
            <Route path="/our-works" element={
              <React.Suspense fallback={<Loader />}>
                <Gallery />
              </React.Suspense>} />
            <Route path="/contact-us" element={
              <React.Suspense fallback={<Loader />}>
                <Contact />
              </React.Suspense>} />
            <Route
              path="*"
              element={<>
                <div className="page-banner change-name">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        <div className="header-text">
                          <h2>Oops !! You took a wrong Direction..</h2>
                          <p>Page Not Found</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
            />
            <Route
              path="/UnderConstruction"
              element={<>
                <div className="page-banner change-name">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        <div className="header-text">
                          <h2>Oops !! There is a problem at out end</h2>
                          <p>Please visit later</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
            />
          </Route>
        </Routes>
      </GlobalProvider>
    </>

  );
}

export default App;


function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

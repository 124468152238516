import React from 'react';

export default (state, action) => {
    switch (action.type) {
        case 'ADD_ITEM':
            return {
                WebsiteData: { ...action.payload }
            }
        case 'REMOVE_ITEM':
            return {
                WebsiteData: state.WebsiteData.filter(item => item !== action.payload)
            }
        default:
            return state;
    }
}
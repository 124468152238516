import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import "./shared.css"
import { GlobalContext } from '../../_helpers/GlobalState';


export function Footer() {
    const { WebsiteData } = useContext(GlobalContext);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    return (
        <>

            <section className="call-to-action">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h2>{WebsiteData?.Website?.Footer?.TopParagraph}</h2>
                        </div>
                        <div className="col-lg-4">
                            <div className="white-button">
                                <Link to="/Contact">Contact Us Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <img src={WebsiteData?.Website?.Footer?.logoUrl} className="footerLogo" alt="RKG tech Logo" />

                        </div>
                        <div className="col-lg-4">
                            <div className="about-widget">
                                <p>{WebsiteData?.Website?.Footer?.Description}</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="location-widget">
                                <h4>{WebsiteData?.Website?.Footer?.OfficeOne.Header}</h4>
                                <p>{WebsiteData?.Website?.Footer?.OfficeOne.Address}</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="customer-care">
                                <h4>{WebsiteData?.Website?.Footer?.OfficeTwo.Header}</h4>
                                <p>{WebsiteData?.Website?.Footer?.OfficeTwo.Address}</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="customer-care">
                                <h4>{WebsiteData?.Website?.Footer?.Contact.Header}</h4>
                                <ul className="info">
                                    {WebsiteData?.Website?.Footer?.Contact.ContactList.map(item => {
                                        return (<li key={item}><a href="#">{item}</a></li>)
                                    })}
                                </ul>
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <div className="sub-footer">
                                <div className="row">
                                    <div className="col-lg-12" style={{ "textAlign": "center" }}>

                                        <p className='credit'>&copy; RKG Technical Services. All Right Reserved</p>
                                    </div>
                                    <div className="col-lg-12">
                                        <a href="#" onClick={scrollToTop} className="scroll-to-top">
                                            Go to Top
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                className="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                    d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
}
